<template>
    <div class="humanity-details">
        <div class="details-html" v-html="details"></div>
    </div>
</template>

<script>
import { getwalkingList } from "@/service";
export default {
    data() {
        return {
            details: "",
        };
    },
    mounted() {
        this.getwalkingList();
    },
    methods: {
        async getwalkingList() {
            const { id = "" } = this.$route.query;
            const params = {
                pageNo: 1,
                pageSize: 1000,
            };
            const { data } = await getwalkingList(params);
            const details = data.list.filter((item) => item.id == id);
            this.details = details[0].rwText || "";
        },
    },
};
</script>

<style lang="scss">
.humanity-details {
    padding: 30px;
    font-size: 28px;
    line-height: 46px;
    .details-html {
        overflow-x: hidden;
        img {
            max-width: 100%;
        }
    }
}
</style>